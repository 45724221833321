import React, { useCallback, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as S from './styles/navbar';
import { Link } from 'react-router-dom'



const Navbar = () => {
  const [slider, setSlider] = useState(false);

  const toggleSlide = useCallback(() => {
    setSlider((prevState) => !prevState);
  }, []);

  return (
    <Box component="nav">
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={toggleSlide} aria-label="Menu">
          </IconButton>
          <Link to={"/"}>
          <S.titleStyles>
           Home
           </S.titleStyles>
            </Link>
           <Link to={"/dashboards"} >
           <S.titleStyles>
           PIGEON
           </S.titleStyles>
            </Link>
          <Drawer anchor="left" open={slider} onClose={toggleSlide}>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
