import styled from 'styled-components';


export const pageContainer = styled.div`
  padding: 20px;
  background: linear-gradient(160deg, #0A2A43 0%, #3A6073 100%);
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const headerStyle = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const listStyle = styled.div`
  list-style: none;
  padding: 0;
  font-size: 1.5rem;
`;

export const listItemStyle = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid #ffffff50;
  padding-bottom: 5px;
`;