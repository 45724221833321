// App.jsx
import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Navbar from './components/navbar';
import Home from './pages/home';
import DashboardList from './pages/dashboards';

const App = () => {
  return (
    <>
    <Navbar />
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/dashboards" element={<DashboardList />} />
    </Routes>
    </>
  );
};

export default App;
