import styled from 'styled-components';


export const titleStyles = styled.div`
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
  margin-right: 20px; // Adds space to the right of each link
`;


export const clearAllStyle = styled.div`
  background: tomato;
`;

export const appBarStyle = styled.div`
  background: #222;
`;