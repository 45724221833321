import './App.css';
import logo from './logo.svg';

const Home = () => {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Brayan Sanchez is currently Developing.
          </p>
          </header>
    </div>
    </>
  );
};

export default Home;