import React, { useState, useEffect } from 'react';
import * as S from './styles/dashboards';
import axios from 'axios';

function DashboardList() {
  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    axios.get('https://brayan-bazan.com/api/dashboards')
      .then(response => {
        setDashboards(response.data);
        setLoading(false); // Set loading to false on successful fetch
      })
      .catch(error => {
        console.error('There was an error fetching the dashboards!', error);
        setLoading(false); // Consider what to do on error, perhaps set an error state
      });
  }, []); // Empty dependency array means this effect runs only once after the initial render

  if (loading) return <p>Loading...</p>; // Loading indicator

  return (
    <div>
      <S.pageContainer>
        
      <h1>
        <S.headerStyle>
        WHY I HATE PIGEON
        </S.headerStyle>
        </h1>
      <ul>
        <S.listStyle>
        {dashboards.map(dashboard => (
          <li key={dashboard.id}>
            <S.listItemStyle>
            {dashboard.title} - {dashboard.graph_type}
            </S.listItemStyle>
          </li>
        ))}
        </S.listStyle>
      </ul>

      </S.pageContainer>
    </div>
  );
}

export default DashboardList;
